.about-banner{
    background-image: url("../../../public/Images/recycling-basket-nature.jpg") !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    padding: 138px 0;
    text-align: center;
}
.main-banner .content h1{
    font-size: 48px;
    color: #fff;
    line-height: 65px;
    margin-bottom: 30px;
}
.main-btn, .inner-main-ftr .ftr form .input-subject button{
    padding: 20px 50px;
    background-color: var(--theme-orange);
    text-transform: uppercase;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    transition: .3s;
}
.main-btn:hover{
    background-color: var(--theme-back);
    color: var(--theme-orange);
}
.main-welcome .main-content{
    display: flex;
    width: 100%;
    position: relative;
}
.main-welcome .left-content{
   
    position: relative;
    padding: 0 40px 0 15px;
}
.main-welcome .left-content .top-txt{
    margin-bottom: 20px;
}
 .top-txt small{
    font-size: 14px;
    display: inline-block;
    background-color: #101010;
    color: #fff;
    font-weight: 700;
    padding: 3px 18px;
    text-transform: uppercase;
    margin-bottom: 18px;
}
/* .left-content .content{
    width: 55%;
} */
 .top-txt h2{
    font-size: 36px;
    font-weight: 300;
}
 .top-txt h2 span{
    font-weight: 900;
}
.txt-p{
    font-size: 21px;
    color: #7A7A7A;
    font-weight: 400;
    margin-bottom: 25px;
}
.main-welcome .bottom-content{
    margin-bottom: 20px;
}
.aboutpage2{
    margin: 75px 0;
    margin-bottom: 10%;
}
.main-populated{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.main-populated .first-box{
    width: 50%;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 15px;
}
.main-populated .first-box .icon{
    margin-right: 15px;
    font-size: 32px;
    line-height: 1;
}
.main-populated .first-box .txt h5{
    font-size: 18px;
}
.btn-2:hover, .inner-main-ftr .ftr form .input-subject button:hover{
    background-color: var(--theme-text);
    color: #fff;
}
.main-welcome .right-img{
    display: flex;
    position: relative;
}
.main-welcome .right-img img{
    object-fit: cover;
    border-radius: 5px;
}
@media screen and (max-width: 992px){
    .right-img img{
        width: 100%;
    }   
}
.main-banner{
    background-image: url("../../../public/Images/imgpsh_fullsize_anim.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 138px 0;
    background-position: center;
    background-blend-mode: multiply;
    background-color: rgba(0, 0, 0, 0.3);
    position: relative;
  }
  .main-banner .content h1{
    font-size: 48px;
    color: #fff;
    font-weight: 700;
    line-height: 65px;
    margin-bottom: 30px;
    z-index: 999;
  }
  .main-btn, .inner-main-ftr .ftr form .input-subject button{
    padding: 20px 50px;
    background-color: var(--theme-orange);
    text-transform: uppercase;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    transition: .3s;
  }
  .main-btn:hover{
    background-color: var(--theme-back);
    color: var(--theme-orange);
  }
  .team {
    padding-top: 80px;
    padding-bottom: 45px;
  }
  
  .team .sec_title {
    margin-top: -3px;
    padding-bottom: 50px;
  }
  
  .team .sec_title h1 {
    color: #222222;
    font-size: 32px;
    font-weight: 400;
    line-height: 36px;
    text-transform: capitalize;
    margin: 0 0 16px;
  }
  
  .team .sec_title .border {
    position: relative;
    overflow: hidden;
    background-color: #f0f0f0;
    height: 1px;
    display: block;
  }
  
  .team .sec_title .border::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 70px;
    height: 1px;
    background-color: #0392ce;
    content: "";
  }
  
  .team_main-box {
    /* padding: 0 15px; */
    /* gap: 30px; */
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  
  .team_main-box .img_box {
    width: calc(33.33% - 15px);
    margin: 0 7px;
    /* height: 100% !important; */
  }
  
  .team_main-box .single_box {
    position: relative;
    margin-bottom: 35px;
    transition: .3s;
    height: 100%;
    overflow: hidden;
  }
  .team_main-box .single_box > div{
    width: 100%;
    height: 100%;
  }
  .team_main-box .single_box:hover{
    transform: translateY(-10px);
    box-shadow: 0px 20px 24px -9px rgba(0, 0, 0, 0.09);
    /* height: 70%; */
  }
  .team_main-box .single_box:hover > div{
    height: -5%;
  }
  /* .team_main-box .single_box:hover .team_text{
    background: linear-gradient(to right, #4458dc 0%, #854fee 100%), radial-gradient(circle at top left, #4458dc, #854fee);
    transition: .3s;
  } */
  /* .team_main-box .single_box:hover .team_text h3,  .team_main-box .single_box:hover .team_text span{
    color: #fff;
  } */
  .team_main-box .single_box img {
    width: 100%;
    height: 77% !important;
    object-fit: cover;
    vertical-align: middle;
    position: relative;
    overflow: hidden;
    transition: .3s;
  }
  .team_main-box .single_box:hover img{
    transform: scale(1.1);
  }
  
  .team_text {
    background: #ffffff;
    padding-top: 18px;
    padding-bottom: 12px;
    /* text-align: center; */
    padding-left: 20px;
    padding-right: 20px;
    transform: translate3d(0px, 0%, 0px);
    transition: all .9s ease;
    transition-delay: 0.10s;
    border-top: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    position: relative;
    opacity: 1;
    transition: .3s;
  }
  .single_box .layer{
    opacity: 0;
  }
  .single_box .layer:hover{
    opacity: 1;
  }
  .team_text h3 {
    color: #222222;
    font-size: 20px;
    margin: 0 0 3px;
    font-weight: 400;
  }
  
  .team_text span { 
    color: #0392ce;
  }
  .team_main-box .layer{
    background-color: rgba(1, 72, 107, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 355px;
    height: auto;
    padding: 15px;
    transition: 0.5s;
    transform: scaleY(150px);
    /* padding-top: 70px; */
  }
  .team_main-box .layer h3{
    color: #ffffff;
    margin: 0 0 3px;
  }
  .team_main-box .layer span{
    color: #fcfcfc;
  }
  .team_main-box .layer .border{
    display: block;
    height: 1px;
    width: 50px;
    background: #ffffff;
    margin: 11px 0 21px;
  }
  .team_main-box .layer ul li{
    color: #ffffff;
    line-height: 32px;
  }
  @media screen and (max-width: 991px){
    .team_main-box .img_box {
      width: calc(50% - 15px);
      margin: 10px 7px;
    }
        
    /* .team_main-box .img_box:first-child{
      margin-top: 30px;
    } */
  }
  @media screen and (max-width: 576px){
    .team_main-box .img_box {
      width: 100%;
      margin: 0 7px;
    }
        
  }
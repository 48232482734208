/* body {
    display: flex;
    justify-content: center;
} */

.page {
    padding: 21px 50px;
    margin: 1% 17%;
    margin-bottom: 0;
    background: white;
    box-shadow: 0px 0px 1px 1px rgba(101, 101, 101);
    min-width: 500px;
    overflow: hidden;
}
.page h1{
    font-size: 40px;
}
#terms-and-conditions {
    font-size: 14px;

    h1 {
        font-size: 34px;
    }

    ol {
        counter-reset: item;
    }

    li {
        display: block;
        margin: 20px 0;
        position: relative;
    }

    li:before {
        position: absolute;
        top: 0;
        margin-left: -50px;
        color: magenta;
    }

}
@media screen and (max-width: 767px){
    .page{
        min-width: 300px;
        padding: 20px 40px;
    }
    .page h1{
        font-size: 30px;
    }
}
@media screen and (max-width: 441px){
    .page{
        margin: 1% 3%;
    }
}
.main-banner_contact{
    background-image: url("../../../public/Images/contact_us.jpg") !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 138px 0;
    text-align: center;
    background-blend-mode: multiply;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .main-banner_contact .content h1{
    font-size: 48px;
    color: #fff;
    line-height: 65px;
    margin-bottom: 30px;
  }
  .main-btn, .inner-main-ftr .ftr form .input-subject button{
    padding: 20px 50px;
    background-color: var(--theme-orange);
    text-transform: uppercase;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    transition: .3s;
  }
  .main-btn:hover{
    background-color: var(--theme-back);
    color: var(--theme-orange);
  }
  .main-contact {
  
    margin-top: 0;
    margin-bottom: 100px;
    z-index: 1;
    position: relative;
}

.inner-contact {
    width: 100%;
    display: flex;
    justify-content: center;
}

.right-form {
    width: 43.138%;
    z-index: 1;
    position: relative;
}
.left-contact{
    box-shadow: 0px 10px 24px -9px rgba(0, 0, 0, 0.05);
}
.right-form .txt {
    box-shadow: 0px 10px 24px -9px rgba(0, 0, 0, 0.05);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: -70px 0px 0px 15px;
    background-color: #fff;
}

.right-form .txt .inner-padding {
    padding: 60px 45px 40px 45px;
}

.top-txt small {
    font-size: 14px;
    display: inline-block;
    background-color: #101010;
    color: #fff;
    font-weight: 700;
    padding: 3px 18px;
    text-transform: uppercase;
    margin-bottom: 18px;
}

.top-txt p {
    font-size: 16px;
    color: #7A7A7A;
    margin-bottom: 50px;
    font-weight: 500;
}

.top-txt h2 {
    font-size: 36px;
    font-weight: 300;
    margin-bottom: 50px;
}

.top-txt h2 span {
    font-weight: 900;
}

.inner-padding .form {
    width: 100%;
    position: relative;
}

.inner-padding .form .form-col-12 {
    width: 100%;
    display: flex;
    gap: 24px;
}

.inner-padding .form .form-col-6 {
    width: 50%;

}

.inner-padding .form .form-col-6 input,
.inner-padding .form .form-col-12 input,
.inner-padding .form .form-col-12 textarea {
    border: 1px solid #e8e8eb;
    border-radius: 0;
    width: 100%;
    margin-bottom: 10px;
    height: 45px;
    padding: 10px 20px;
    font-size: 14px;
    outline: none;
}

.inner-padding .form .form-col-12 textarea {
    height: 133px;
}

.inner-padding .form .form-col-12 a {
    width: 100%;
    text-align: center;
    background-color: var(--theme-text);
}
.inner-padding .form .form-col-12 a:hover{
    background-color: var(--theme-back);
    color: #fff;
}

.left-contact {
    width: 33.862%;
    min-height: 1px;
    display: flex;
    position: relative;
    margin: -70px 15px 0px 0px;
}

.inner-left-contact {
    background-color: #f7f9fb;
    padding: 50px 30px 30px 30px;
}

.inner-left-contact .top-txt {
    text-align: center;
    margin-bottom: 50px;
}

.left-contact .left-text p {
    margin-bottom: 20px;
    font-size: 16px;
    color: #666666;
    font-weight: 400;
}

.left-contact .left-text ul li i {
    margin-right: 10px;
    color: var(--theme-text);
}

.left-contact .left-text ul li {
    font-size: 16px;
    color: #666666;
    font-weight: 400;
}
@media screen and (max-width: 991px){
    .inner-contact{
        flex-wrap: wrap;
    }
    .right-form{
        width: 100%;
    }    
    .left-contact{
        width: 100%;
        margin: 0;
        display: block;
        text-align: center;
    }
    .right-form .txt{
        margin: 0;
    }
}
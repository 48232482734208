.main-welcome .main-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    /* margin: 0% 8%; */
}

.main-welcome{
  margin-bottom: 10%;
}
.main-welcome .left-content {
    position: relative;
    padding: 0 40px 0 15px;
}

.about-h2 {
    background-color: #ededed;
    width: 45%;
    padding: 10px;
    border-radius: 7px;
}
.main_title h2 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 15px;
    line-height: 50px;
    text-transform: uppercase;
}

.about-content {
    color: #777;
    font-weight: 400;
    line-height: 26px;
    font-size: 15px;
    width: 83%;
}

.primary_btn {
    display: inline-block;
    padding: 0px 24px;
    color: #fff;
    letter-spacing: 0px;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 46px;
    outline: none !important;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    border-radius: 5px;
    background-origin: border-box;
    background-clip: content-box, border-box;
    background-image: linear-gradient(to right, #4458dc 0%, #854fee 100%), radial-gradient(circle at top left, #4458dc, #854fee);
    border: double 2px transparent;
    box-shadow: 0px 10px 30px rgba(118, 85, 225, 0.3);
}
.right-img img{
    width: 100%;
    border-radius: 5px;
    height: 100%;
    /* padding-right: 30px; */
}
/* .main-content{
    gap: 25px;
} */
.lets-content{
    padding-left: 65px;
}
@media screen and (max-width: 767px){
  .lets-content{
    padding-left: 0;
    padding-top: 14%;
  }
  
}
@media screen and (max-width:767px){
    .about-content{
        width: 100%;
    }
    .right-img img{
        width: 187%;
        margin-top: 30px;
    }
    
}
@media screen and (max-width: 991px){
    .main-welcome .main-content{
        margin: 0;
    }   
    .right-img img{
        width: 187%;
        margin-top: 30px;
    }
}
.lineleft {
    animation: 6s anim-lineleft ease-out;
  }
  @keyframes anim-lineleft {
    0% {
      opacity: 0;
      transform: translatex(-80%);
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: translatex(0%);
    }
    100% {
      opacity: 1;
      transform: translatex(0%);
    }
  }
  .lineright {
    animation: 6s anim-lineright ease-out;
  }
  @keyframes anim-lineright {
    0% {
      opacity: 0;
      transform: translatex(80%);
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: translatex(0%);
    }
    100% {
      opacity: 1;
      transform: translatex(0%);
    }
  }
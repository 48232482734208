
.home_banner_area {
    z-index: 1;
    /* background: url('../../../public/Images/welcome.jpg') no-repeat top center; */
    background-position: center;
    background-size: cover;
}



.home_banner_area .banner_inner {
    width: 100%;
}
.home_right_img img{
    width: 120%;
    height: 100%;
    object-fit: cover;
    animation: 4s zoom ;
    transition: .3s;
}
@keyframes zoom{
    0%{
        opacity: 0;
    }
    50%{
        opacity: 50%;
    }
    100%{
        opacity: 100%;
    }
}
@media (min-width: 991px) {
    .row {
        justify-content: center;
        flex-wrap: inherit !important;
        /* padding: 0% 8%; */
        margin: 0px !important;
    }
}

@media (max-width: 1480px) {
    .home_banner_area .banner_inner .home_right_img img {
        max-width: 100%;
        height: auto;
    }
}

.home-back{
    background-image: url("../../../../public/Images/homebanner.jpg") !important;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 138px 0;
    width: 100%;
}

.home_banner_area .banner_inner .col-lg-7 {
    vertical-align: middle;
    align-self: center;
}

.home_banner_area .banner_inner .banner_content {
    text-align: left;
    /* width: 60/%; */
    margin: auto;
}

@media (max-width: 991px) {
    .home_banner_area .banner_inner .banner_content{
        width: 100%;
    }
}

.home_banner_area .banner_inner .banner_content h3 {
    font-size: 40px;
    margin-bottom: 20px;
    position: relative;
}

.home_banner_area .banner_inner .banner_content h3:after {
    content: '';
    width: 410px;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 23%;
    background: #000000;
}

@media (max-width: 575px) {
    .home_banner_area .banner_inner .banner_content h3:after {
        display: none;
    }
}

.home_banner_area .banner_inner .banner_content h1 {
    margin-top: 20px;
    color: #202020;
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 25px;
}
.home_banner_area{
    padding-bottom: 50px;
}

@media (max-width: 1024px) {
    .home_banner_area .banner_inner .banner_content h1 {
        font-size: 60px;
    }
}

@media (max-width: 767px) {
    .home_banner_area .banner_inner .banner_content h1 {
        font-size: 40px;
    }
}
@media (max-width: 567px) {
    .home_banner_area .banner_inner .banner_content h1 {
        font-size: 33px;
        line-height: 39px;
    }
}

.home_banner_area .banner_inner .banner_content h5 {
    color: #777;
    font-weight: 400;
    line-height: 26px;
    font-size: 20px;
    width: 83%;
}

.home_banner_area .banner_inner .banner_content .primary_btn {
    margin-right: 20px;
    margin-top: 36px;
}

.banner_area {
    position: relative;
    z-index: 1;
    min-height: 485px;
}

@media (max-width: 1199px) {
    .banner_area {
        min-height: 350px;
    }
}

.banner_area .banner_inner {
    position: relative;
    overflow: hidden;
    width: 100%;
    min-height: 485px;
    /* background: url('../img/banner/common-banner.png') no-repeat right bottom; */
    background-size: 90% 85%;
    width: 98%;
    z-index: 1;
}

@media (max-width: 1199px) {
    .banner_area .banner_inner {
        min-height: 350px;
    }
}

@media (max-width: 991px) {
    .banner_area .banner_inner {
        background: #854fee;
        width: 100%;
    }
}

.banner_area .banner_inner .banner_content {
    margin-left: 50px;
    margin-top: 100px;
}

@media (max-width: 991px) {
    .banner_area .banner_inner .banner_content {
        margin-left: 0px;
        margin-top: 50px;
    }
}

.banner_area .banner_inner .banner_content h2 {
    color: #fff;
    font-size: 50px;
    font-family: "Rubik", sans-serif;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 700;
}

@media (max-width: 991px) {
    .banner_area .banner_inner .banner_content h2 {
        font-size: 30px;
    }
}

.banner_area .banner_inner .banner_content .page_link a {
    font-size: 14px;
    color: #fff;
    font-family: "Roboto", sans-serif;
    margin-right: 20px;
    position: relative;
}

.banner_area .banner_inner .banner_content .page_link a:before {
    content: "-";
    position: absolute;
    right: -14px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.banner_area .banner_inner .banner_content .page_link a:last-child {
    margin-right: 0px;
}

.banner_area .banner_inner .banner_content .page_link a:last-child:before {
    display: none;
}

.lineUp {
    animation: 6s anim-lineUp ease-out;
  }
  @keyframes anim-lineUp {
    0% {
      opacity: 0;
      transform: translateY(80%);
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: translateY(0%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
  .space{
    padding: 12% 0;
    padding-top: 10% !important;
    margin-bottom: 10%;
  }
  .home_banner_area h6{
   color: #202020;
   font-size: 20px;
   font-weight: 600;
   /* background-color: rgba(0, 0, 0, 0.2);
   color: #fff;
   width: 150px;
   text-align: center;
   padding: 10px 0; */
  }
/* Header */

body {
  font-family: "DM Sans", sans-serif;
}

a {
  display: inline-block;
  color: darkslateblue;
  text-decoration: none;
}

li {
  display: inline-block;
}
.fixed-nav{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 99999;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}

.header-area .header-inner-box {
  display: flex;
  justify-content: space-between;
  margin-top: -18px;
}
.header-area{
  position: relative;
}

.main-menu li.nav-item {
  margin-right: 45px;
}

.header-content {
  padding: 1%;
}

/* .headerBorder {
  color: darkslateblue;
  border-bottom: 1px solid darkslateblue;
  padding-bottom: 20px !important;
  transform: translateX(0);
} */

.main-menu .navbar-nav .nav-link {
  font-size: 15px;
  font-weight: 600;
  display: block;
  padding: 35px 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: .3s;
  position: relative;
}
.main-menu .navbar-nav .nav-link::before{
  content: '';
  position: absolute;
  bottom: 12px;
  left: 0;
  width: 0;
  height: 1px;
  background-color: darkslateblue;
  transition: .3s;
}
.main-menu .navbar-nav .nav-link:hover::before{
  width: 100%;
}
.main-menu .navbar-nav .nav-link.headerBorder::before{
  width: 100%;
}

/* .main-menu li.nav-item:hover>a{
  color: darkslateblue;
  border-bottom: 1px solid darkslateblue;
  padding-bottom: 20px !important;
  transform: translateX(100%);
} */

.logo {
  margin-top: 15px;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-footer {
  position: relative;
}

.footer-content {
  position: relative;
  padding: 85px 0px 80px 0px;
}

.footer-content:before {
  position: absolute;
  content: '';
  width: 744px;
  height: 365px;
  top: 50px;
  right: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  animation-name: float-bob;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: float-bob;
  -webkit-animation-duration: 30s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: float-bob;
  -moz-animation-duration: 30s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob;
  -ms-animation-duration: 30s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob;
  -o-animation-duration: 30s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

.footer-content .logo-widget {
  position: relative;
  margin-top: -5px;
}

.footer-content .logo-widget .footer-social li {
  position: relative;
  display: inline-block;
  margin-right: 9px;
}

.footer-content .logo-widget .footer-social li:last-child {
  margin-right: 0px;
}

.footer-content .logo-widget .footer-social li a {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 42px;
  line-height: 42px;
  background: #2e3138;
  color: #9ea0a9;
  text-align: center;
  border-radius: 50%;
}

.footer-content .logo-widget .footer-social li a:hover {
  color: #ffffff;
  background: blue;
}

.footer-content .logo-widget .logo-box {
  margin-bottom: 25px;
}

.footer-content .logo-widget .text p {
  color: #9ea0a9;
  margin-bottom: 32px;
}

.footer-content .footer-title {
  position: relative;
  font-size: 24px;
  line-height: 35px;
  font-family: 'Playfair Display', serif;
  color: black;
  font-weight: 700;
  margin-bottom: 27px;
}

.footer-content .service-widget .list li {
  display: block;
  margin-bottom: 12px;
}

.footer-content .service-widget .list li a {
  position: relative;
  display: inline-block;
  color: #9ea0a9;
}

.footer-content .service-widget .list li a:hover {
  color: blue;
}

.footer-content .contact-widget p {
  color: #9ea0a9;
  margin-bottom: 15px;
}

/* .footer-content .contact-widget {
  margin-left: 80px;
} */

.footer-content .contact-widget .footer-title {
  margin-bottom: 29px;
}

/** footer-bottom **/

.footer-bottom {
  position: relative;
  bottom: -25px;
  background: #d7d7d7;
  padding: 25px 0px 22px 0px;
}

.footer-bottom .copyright,
.footer-bottom .copyright a,
.footer-bottom .footer-nav li a {
  position: relative;
  color: #000000;
}

.footer-bottom .footer-nav {
  position: relative;
  text-align: right;
}

.footer-bottom .footer-nav li {
  position: relative;
  display: inline-block;
  margin-left: 29px;
}

.footer-bottom .footer-nav li:first-child {
  margin-left: 0px;
}

.footer-bottom .footer-nav li:before {
  position: absolute;
  content: '';
  background: #9ea0a9;
  width: 1px;
  height: 14px;
  top: 7px;
  left: -18px;
}

.footer-bottom .footer-nav li:first-child:before {
  display: none;
}

.logo-box img {
  max-width: 120px;
}
.whatsapp-icon{
  position: fixed;
  right: 50px;
  bottom: 50px;
  font-size: 50px;
  color: rgb(37, 211, 102);
}

@media screen and (max-width: 991px) {
  .footer-content .contact-widget {
    margin-left: 0;
  }

}

@media screen and (max-width: 767px) {
  .footer-bottom .footer-nav {
    text-align: start;
    margin-top: 15px;
  }

}

.toggle {
  margin-top: 30px;
  position: fixed;
  top: 0;
  right: 15px;
}

.toggle ul li.nav-item {
  margin-right: 0;
}

.toggle ul li .nav-link {
  padding: 10px 0 !important;
}
.navbar-nav .navbar-nav .nav-link{
  padding: 15px 0;
}
.navbar-nav .navbar-nav{
  position: absolute;
  top: 98px;
  right: -15px;
  background-color: rgba(236, 236, 236, 0.8);
  font-size: 18px;
  z-index: 9999;
  padding: 15px;
}
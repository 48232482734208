.experience {
    /* box-shadow: 0px 15px 30px rgba(77, 87, 222, 0.3); */
    background: #fff;
    border-radius: 10px;
    text-align: center;
    padding: 2% 0.1% 2% 0px;
    width: calc(22% - 15px);
    margin: 10px 7px;
}

.icon {
    height: 25%;
    width: 25%;
    margin: 10px;
}

.counter {
    font-size: 29px;
    font-weight: 600;
    color: #4a4a4a;
}

.experience span {
    font-size: 29px;
    font-weight: 600;
    color: #4a4a4a;
}

.experience p {
    word-spacing: 1px;
    letter-spacing: 0.2px;
}

@media screen and (max-width: 992px){
    .experience{
        width: calc(50% - 15px);
        margin: 10px 7px;
    }   
}
@media screen and (max-width: 576px){
    .experience{
        width: 100%;
        margin: 10px 7px;
    }   
}
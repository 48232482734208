.main-watse{
    /* background-image: url('../public/images/banner-1.jpeg'); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center left;
    /* padding: 90px 0 70px 0; */
    /* background-blend-mode: multiply;
    background-color: rgb(246, 246, 246);
    opacity: 0.5; */
    position: relative;
    padding-top: 80px;
}
.main-watse::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: #f6f6f6; */
    opacity: 0.75;
    z-index: 1;
}
.inner-waste{
    position: relative;
    z-index: 999;
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 25px;
}
/* .form-control {
    width: 100%;
    padding: 6px;
    border-radius: 5px;
    font-size: 15px;
} */
.form-control{
    border: none;
    border-bottom: 2px solid #666666;
    border-radius: 0;
    padding-left: 0;
    padding-bottom: 15px;
}
.form-control::placeholder{
    font-size: 18px;
    padding-bottom: 2px;
    padding-left: 0;
}
.custom-input,
.custom-textarea {
    background-color: #f5f5f5;
    border-radius: 10px;

}
form [type="button"]{
    margin-top: 20px;
    width: 17%;
    /* text-align: center; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to right, #4458dc 0%, #854fee 100%), radial-gradient(circle at top left, #4458dc, #854fee);
    padding: 12px 0;
}
@media screen and (max-width: 767px) {
  form [type="button"]{
    width: 30%;
  }    
}
.custom-input {
    padding: 10px;
}

.custom-textarea {
    padding: 10px;
}

.top-content {
    width: 100% !important; 
    margin-top: 10%;
}

.touch-row {
    display: inline-flex !important;
    /* padding: 0% 13%; */
}

/* .touch-container {
    min-height: 710px;
    max-width: 100% !important;
    background-color: #f7f7f7;
} */

.touch-row-2 {
    background-color: white;
    box-shadow: 0px 6px 59px rgba(8, 8, 20, 0.11);
    margin: 0% 2%;
    /* max-width: 60%; */
    padding: 1% 4%;
    border-radius: 10px;
    justify-content: space-between;
    padding-bottom: 30px;
}
.touch-row-2 h3{
    padding-top: 20px;
    position: relative;
}
.top-content h1{
    position: relative;
}
.top-content h1::before{
    content: '';
    width: 20%;
    height: 2px;
    background-color: #4458dc;
    position: absolute;
    bottom: -2px;
    left: 0;
}

.custom-textarea {
    border: none;
    border-bottom: 1px solid #ccc;
    font-size: 14px;
    color: #495057;
    background-color: white;
    padding-right: 20px;
    padding-top: 6px;
    padding-bottom: 6px;
    outline: none;
    width: 20%;
    font-size: 19px;
}

.main-footer {
    display: flex;
}

.personal-details {
    text-align: center;
    margin-left: -104px;
    border-radius: 10px;
    padding: 0px 2%;
}

.d-flex {
    display: flex;
}
@media screen and (max-width: 567px){
    .touch-row-2{
        margin: 0;
        max-width: 100% !important;
    }   
    .touch-row{
        padding: 0;
    }
}
@media screen and (max-width: 767px){
    .touch-row-2{
        max-width: 95%;
    }  
}
.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(to right, #4458dc 0%, #854fee 100%), radial-gradient(circle at top left, #4458dc, #854fee);
  
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      /* font-size: 190px; */
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}